import moment from "moment";
import React from "react";
import { CSVLink } from "react-csv";

export default function CsvDownloader(props: any) {
  function getCSVRows() {
    if (props.csvRows) return props.csvRows;
    let cls = [{ id: "_id", displayName: "ID" }, ...props.columns];
    cls = cls
      .filter((elt: any) => (elt.id || elt.accessorKey) != undefined && !elt.nocsv)
      .map((elt: any) => ({ key: elt.id || elt.accessorKey, label: elt.header }));
    return cls;
  }
  function getCSVData() {
    const csvData: any = props.table.getFilteredRowModel().rows.length
      ? props.table.getFilteredRowModel().rows.map((elt: any) => {
          const original: any = { ...elt.original };
          Object.keys(original).forEach((key) => {
            if (typeof original[key] == "boolean") original[key] = original[key] ? "Oui" : "Non";
            else if (!isNaN(original[key])) original[key] = original[key] + "";
            else if (original[key] && !isNaN(new Date(original[key])?.getTime()))
              original[key] = moment(original[key]).format("DD/MM/YYYY");
            else if (original[key]?.name) original[key] = original[key].name;
            else if (Array.isArray(original[key]) && original[key][0]?.name)
              original[key] = original[key].map((elt: any) => elt?.name).join(" ,");
            else original[key] = original[key].toString();
          });
          return original;
        })
      : props.data;
    return csvData;
  }
  return (
    <CSVLink
      filename={(props.title?.toLowerCase() || "list") + ".csv"}
      className="button btn-secondary addbtn"
      data={getCSVData() || []}
      headers={getCSVRows()}
    >
      Télecharger la liste
    </CSVLink>
  );
}
