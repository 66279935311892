import moment from "moment";
import React from "react";
import Table from "../../components/table/table";
import Button from "../../components/form/button/button";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../context/app.context";
export function statusMission(status: any) {
  if (status == "enattente") return { title: "En attente", class: "badge-warning" };
  if (status == "annulee") return { title: "Annulée", class: "badge-danger" };
  if (status == "terminée") return { title: "Disponible", class: "badge-success" };
  return { title: "", class: "" };
}
export default function MissionslistPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { appInfos } = useAppContext();
  const mission = searchParams.get("mission");
  function getEmetteur(elt: any) {
    return elt.type == "commission"
      ? "SAS ARÈS"
      : elt.type == "rémunération"
        ? elt?.idJobbeur?.prenom + " " + elt?.idJobbeur?.nom
        : elt?.idEntreprise?.NomEntreprise;
  }
  function getRcepteur(elt: any) {
    return elt.type == "commission"
      ? elt.idEntreprise?.NomEntreprise
      : elt.type == "rémunération"
        ? elt?.idEntreprise?.NomEntreprise
        : elt?.idJobbeur?.prenom + " " + elt?.idJobbeur?.nom;
  }
  const columns = [
    {
      accessorKey: "status",
      header: "Statut",
      filterVariant: "multi-select",
      filterSelectOptions: [{ label: "Disponible", value: "done" }],
      size: 30,
      Cell: () => {
        return <span className={"badge badge-success"}>{"Disponible"}</span>;
      },
    },
    {
      accessorKey: "type",
      filterVariant: "multi-select",
      filterSelectOptions: [
        { label: "rémunération", value: "rémunération" },
        { label: "commission", value: "commission" },
      ],
      header: "Type",
      size: 30,
    },
    {
      accessorKey: "numFacture",
      header: "Numéro",
      size: 30,
    },
    {
      accessorKey: "emetteur",
      header: "Émetteur",
      accessorFn: (originalRow: any) => getEmetteur(originalRow),
      size: 30,
    },
    {
      accessorKey: "recepteur",
      header: "Adressé à",
      accessorFn: (originalRow: any) => getRcepteur(originalRow),
      size: 30,
    },
    {
      accessorKey: "priceHT",
      header: "prix HT",
      filterVariant: "range",
      accessorFn: (originalRow: any) =>
        originalRow?.priceHT && !isNaN(+originalRow?.priceHT) ? (+originalRow?.priceHT)?.toFixed(2) : "",
      size: 30,
    },
    {
      accessorKey: "priceTTC",
      filterVariant: "range",
      accessorFn: (originalRow: any) =>
        originalRow?.priceTTC && !isNaN(+originalRow?.priceTTC) ? (+originalRow?.priceTTC)?.toFixed(2) : "",
      header: "prix TTC",
      size: 30,
    },
    {
      accessorKey: "tvapercent",
      filterVariant: "range",
      header: "TVA(%)",
      size: 30,
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorFn: (originalRow: any) => new Date(originalRow.facturationDate),
      header: "Date de facturation",
      filterVariant: "date-range",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => moment(+cell.getValue()).format("DD/MM/YYYY"),
      type: "date",
    },
    {
      accessorFn: (originalRow: any) => new Date(originalRow.created_at),
      header: "Date de création",
      filterVariant: "date-range",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => moment(+cell.getValue()).format("DD/MM/YYYY"),
      type: "date",
    },
    {
      accessorKey: "mission",
      header: "Mission",
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: any) => {
        const id = cell?.row?.original?.idMission;
        const disabled = !id;
        return (
          <Link
            to={"/missions/" + id}
            style={{ pointerEvents: disabled ? "none" : "auto" }}
            // disabled={cell.row.original.status != "terminée"}
            className={`btn btn-secondary btnsmall ${disabled ? "btn-secondary-disabled" : ""}`}
            title="Voir"
          >
            <span>Voir</span>
          </Link>
        );
      },
    },
  ];

  function DownloadFactureBtn(p: any) {
    return (
      <a
        href={
          process.env.REACT_APP_SERVER_URL +
          "/api/factures/facturepdf/" +
          (p.row.original._id || p.row.original.id) +
          "?token=" +
          appInfos.token
        }
      >
        <span className="action">
          <img style={{ marginLeft: 10 }} src="/images/download.svg" alt="supprimer" />
        </span>
      </a>
    );
  }

  return (
    <Table
      extracolumnActions={DownloadFactureBtn}
      dataUrl={"factures" + (mission ? "?mission=" + mission : "")}
      columns={columns}
      permission="factures"
      title="Factures"
    />
  );
}
