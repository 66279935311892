import React from "react";
import "./App.css";
import AppRoutes from "./routes/app.routes";
import AppContextProvider from "./context/app.context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Interceptor from "./components/interceptor/interceptor";
import { createTheme, ThemeProvider } from "@mui/material";
import { Worker } from "@react-pdf-viewer/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/fr";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { appColors } from "./color";
function App() {
  return (
    <ThemeProvider theme={createTheme({ palette: { primary: { main: appColors.primary100 } } })}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
        <div className="container-fluid root">
          <ToastContainer limit={1} autoClose={false} bodyClassName={"error-toast"} />
          <AppContextProvider>
            <Interceptor>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <AppRoutes />
              </Worker>
            </Interceptor>
          </AppContextProvider>
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
//94.140.15.15
//94.140.14.14
