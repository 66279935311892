/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axiosInstance from "../../config/axios";
import Loader from "../../components/loader/loader";
import Chart from "react-apexcharts";
import DateTimeInput from "../../components/form/inputs/datetime-input/date-time-input";
import { appColors } from "../../color";

export default function HomePage() {
  const [loading, setloading] = useState(false);
  const [userCount, setuserCount] = useState(0);
  const [missionCount, setMissionCount] = useState(0);
  const [missionperDay, setMissionPerDay] = useState([]);
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date(endDate.getFullYear(), endDate.getMonth(), 1));

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  async function getData() {
    if (!userCount) setloading(true);
    try {
      if (startDate.getTime() > endDate.getTime()) return;
      const { data } = await axiosInstance.post("kpi", { startDate, endDate });
      setuserCount(data.userCount);
      setMissionCount(data.missionCount);
      setMissionPerDay(data.missionPerDay);
    } catch (e) {
      /* empty */
    }
    setloading(false);
  }

  if (loading) return <Loader />;
  return (
    <div className="flexcolumn">
      <div className="row gy-4">
        <div className="col flexcolumn">
          <div className="card bgprimary p-3 mb-4 flexcolumn">
            <h2 className="bold">{userCount}</h2>
            <span>{"Nombre Total d'utilisateurs"}</span>
          </div>
          <div className="card p-3 flexcolumn">
            <h2 className="bold">{missionCount}</h2>
            <span>{"Nombre Total de Missions"}</span>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-9">
          <PerDayChart
            series={[
              {
                name: "Nouvelles missions",
                data: missionperDay.map((elt: any) => elt.count),
              },
            ]}
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            xData={missionperDay.map((elt: any) => elt._id)}
            title={"Statistiques des missions"}
          />
        </div>
      </div>
    </div>
  );
}

function PerDayChart({ series, title, xData, startDate, endDate, setStartDate, setEndDate }: any) {
  const config = {
    options: {
      yaxis: {
        min: 0,
        labels: {
          style: {
            colors: appColors.textgray,
            fontSize: "12px",
          },
        },
      },

      xaxis: {
        categories: xData,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 6,
        labels: {
          rotate: 0,
          rotateAlways: true,
          offsetX: 15,
          offsetY: 15,
          style: {
            colors: appColors.textgray,
            fontSize: "12px",
          },
        },
        crosshairs: {
          position: "front",
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: "12px",
          },
        },
      },
      colors: [appColors.primary100],
      grid: { strokeDashArray: 4 },
      chart: {
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.4,
          opacityTo: 0.2,
          stops: [15, 120, 100],
        },
      },
    },
    series: series,
  };
  return (
    <div className="card p-3 pb-0">
      <div className="row">
        <div className="col-sm-6 col-md mb-4 mt-2">
          <span className="title">{title}</span>
        </div>
        <div className="col-sm-6  col-md">
          <DateTimeInput max={endDate} onChange={setStartDate} value={startDate} dateLabel="de" type="date" />
        </div>
        <div className="col-sm-6 col-md">
          <DateTimeInput min={startDate} onChange={setEndDate} value={endDate} dateLabel="jusqu'a" type="date" />
        </div>
      </div>
      <Chart options={config.options} series={config.series} height={300} width={"100%"} type="area" />
    </div>
  );
}
