/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import TextInput from "../form/inputs/text-input/text-input";
import { chooseFile, readLocalFile } from "../../utils/utils";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import heic2any from "heic2any";

export default function File(props?: {
  filepath?: string;
  label?: string;
  onSelect?: any;
  type?: string;
  minHeight?: number;
  maxWidth?: number;
}) {
  const [file, setFile] = useState<any>("");
  const [fileURL, setFileURL] = useState<any>("");
  const [fileName, setFileName] = useState<any>("");
  const [src] = useState(props?.filepath);
  const [displayURL, setDisplayURL] = useState<any>("");

  async function onSelect() {
    const files: any = await chooseFile({
      patternFile: props?.type === "pdf"
        ? [".pdf", ".PDF"]
        : [".jpeg", ".png", ".webp", ".heic", ".HEIC", ".jpg"],
      multiple: false,
    });

    const selectedFile = files[0];
    setFileName(selectedFile.name);

    const isHeic = [".heic", ".HEIC"].includes(
      selectedFile.name.slice(selectedFile.name.lastIndexOf(".")).toLowerCase()
    );

    if (isHeic) {
      try {
        const convertedBlob: any = await heic2any({ blob: selectedFile, toType: "image/jpeg" });
        const convertedURL = URL.createObjectURL(convertedBlob);
        setDisplayURL(convertedURL);
        setFileURL(convertedURL);
      } catch (error) {
        console.error("Error converting HEIC from selected file:", error);
        return;
      }
    } else {
      const url = URL.createObjectURL(selectedFile);
      setFileURL(url);
      setDisplayURL(url);
    }

    const fileData = await readLocalFile(selectedFile);
    setFile(fileData);
    if (props?.onSelect) props.onSelect(selectedFile);
  }

  useEffect(() => {
    if (src && !file) {
      const isHeic = [".heic", ".HEIC"].includes(
        src.slice(src.lastIndexOf(".")).toLowerCase()
      );
      if (isHeic) {
        const fetchAndConvertHeic = async () => {
          try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL + "/" + src, {
              cache: "no-store", // Disable caching to force fresh fetch
            });
            if (!response.ok) {
              throw new Error(`Fetch failed with status: ${response.status}`);
            }
            const blob = await response.blob();
            if (blob.size === 0) {
              throw new Error("Received empty blob from server");
            }
            console.log("Fetched blob size:", blob.size, "type:", blob.type);
            const convertedBlob: any = await heic2any({ blob, toType: "image/jpeg" });
            const convertedURL = URL.createObjectURL(convertedBlob);
            setDisplayURL(convertedURL);
          } catch (error) {
            console.error("Error processing HEIC from filepath:", error);
          }
        };
        fetchAndConvertHeic();
      } else {
        setDisplayURL(process.env.REACT_APP_SERVER_URL + "/" + src);
      }
    }
  }, [src, file]);

  const fileExtension = (fileName || src || "")
    ?.split(".")
    ?.reverse()[0]
    ?.toLowerCase();
  const isImg = ["png", "jpeg", "webp", "jpg", "heic"].includes(fileExtension);
  const isPDF = ["pdf"].includes(fileExtension);

  useEffect(() => {
    return () => {
      if (displayURL) URL.revokeObjectURL(displayURL);
      if (fileURL) URL.revokeObjectURL(fileURL);
    };
  }, [displayURL, fileURL]);

  return (
    <>
      <div className="col-sm-12 col-md-12 mt-2">
        <div className="row">
          <div className="col-12" onClick={onSelect}>
            <TextInput
              value={fileName || src}
              containerStyle={{ pointerEvents: "none" }}
              label={props?.label}
              placeHolder="Sélectionner un fichier"
              rightIcon={
                fileName || src ? (
                  <img src="/images/edit.svg" alt="" />
                ) : (
                  <img src="/images/edit.svg" alt="" />
                )
              }
            />
          </div>
        </div>
      </div>

      <div
        className="col-sm-12 col-md-12 mt-2 p-2"
        style={{
          backgroundColor: "#F0F0F0",
          minHeight: props?.minHeight || 330,
          maxHeight: 600,
          maxWidth: props?.maxWidth || 500,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          overflow: "scroll",
        }}
      >
        {props?.filepath || file ? (
          <a target="_blank" href={fileURL || process.env.REACT_APP_SERVER_URL + "/" + props?.filepath}>
            {(props?.type === "pdf" && isImg && !file) || (props?.type !== "pdf" && (file || src)) ? (
              <img
                style={{ width: "100%", maxHeight: "100%" }}
                alt=""
                src={displayURL || (src && !file ? process.env.REACT_APP_SERVER_URL + "/" + src : file)}
              />
            ) : (
              props?.type === "pdf" && (fileURL || (src && isPDF)) && (
                <Viewer fileUrl={fileURL || process.env.REACT_APP_SERVER_URL + "/" + src} />
              )
            )}
          </a>
        ) : null}
      </div>
    </>
  );
}
