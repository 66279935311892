/* eslint-disable no-empty */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  MaterialReactTable,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import "./table.css";
import Button from "../form/button/button";
import "react-responsive-modal/styles.css";
import Modal from "../modal/modal";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../context/app.context";
import axiosInstance from "../../config/axios";
import { cloneDeep } from "lodash";
import CsvDownloader from "./csvdownload/csvdownload";

export default function Table(props: TabProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate= useNavigate()
  const location =useLocation();
  const [ready, setready] = useState(false);
  const [initialState, setInitialState] = useState<TabStateProps>({
    columnFilters: [],
    globalFilter: "",
    sorting: [],
    pagination: {
      pageIndex: 0,
      pageSize: 100,
    },
  });
  function routeParamsToState() {
    const columnFiltersParam = searchParams.getAll("columnFilters[]");
    //const searchTerm = searchParams.get("searchTerm");
    const sortingParam = searchParams.getAll("sorting[]");
    const pageIndexParam = searchParams.get("page");
    const pageSizeParam = searchParams.get("pageSize");
    const initState = cloneDeep(initialState);
    setInitialState({
      ...initState,
      columnFilters: columnFiltersParam?.map((filter) => JSON.parse(filter)) || [],
      //globalFilter: searchTerm || "",
      globalFilter: "",
      sorting: sortingParam.map((sort) => JSON.parse(sort)) || [],
      pagination: {
        pageIndex: pageIndexParam ? Number(pageIndexParam) - 1 : 0,
        pageSize: pageSizeParam ? Number(pageSizeParam) : 10,
      },
    });
  }
  function stateToRouteParams(obj: any) {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete("page")
    newParams.delete("pageSize")
    newParams.delete("sorting[]")
    newParams.delete("searchTerm")
    newParams.delete("columnFilters[]")
    if (obj.pagination) {
      newParams.set(`page`, obj.pagination.pageIndex + 1);
      newParams.set(`pageSize`, obj.pagination.pageSize);
    }
    if (obj.sorting) {
      obj.sorting.forEach((sort: any) => {
        newParams.append("sorting[]", JSON.stringify(sort));
      });
    }
    if (obj.globalFilter) {
      newParams.append("searchTerm", obj.globalFilter);
    }
    if (obj.columnFilters) {
      obj.columnFilters.forEach((filter: any) => {
        newParams.append("columnFilters[]", JSON.stringify(filter));
      });
    }
    return newParams;
  }
  function onTabStateChange(obj: any) {
  //  setSearchParams(stateToRouteParams(obj));
    navigate(location.pathname+"?"+stateToRouteParams(obj).toString(), { replace: true })
  }

  useEffect(() => {
    routeParamsToState();
    setready(true);
    return ()=>{}
   
  }, []);
  return ready ? <TabContent onTabStateChange={onTabStateChange} {...props} initialState={initialState} /> : null;
}

function TabContent(props: TabContentProps) {
  const [openmodal, setopenmodal] = useState(false);
  const [selectedrow, setselectedrow] = useState("");
  const iniState = props.initialState;
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(iniState?.columnFilters);
  const [globalFilter, setGlobalFilter] = useState(iniState.globalFilter);
  const [sorting, setSorting] = useState<MRT_SortingState>(iniState.sorting);
  const [pagination, setPagination] = useState<MRT_PaginationState>(iniState.pagination);
  const [isSaving, setIsSaving] = useState(props.isSaving);
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const [data, setdata] = useState(props.data);
  const { appInfos } = useAppContext();
  const emplyee = props.permission && appInfos.user.type == "Employee";
  const perms = appInfos.user?.permissions && appInfos.user?.permissions[props.permission];
  const disableAdd = emplyee && !perms?.find((elt: any) => elt == "add");
  const disableEdit = emplyee && !perms?.find((elt: any) => elt == "edit");
  const disableDelete = emplyee && !perms?.find((elt: any) => elt == "delete");
  useEffect(() => {
    if (props.dataUrl) {
      (async () => {
        setIsLoading(true);
        try {
          const result = await axiosInstance.get(props.dataUrl);
          let d = result.data.data || result.data;
          if (props.mapData) {
            d = d.map((elt: any) => props?.mapData(elt));
          }
          setdata(d);
        } catch (e) {}
        setIsLoading(false);
      })();
    } else setdata(props.data);
  }, []);
  useEffect(() => {
    if (props.onTabStateChange) props.onTabStateChange({ sorting, columnFilters, pagination, globalFilter });
  }, [sorting, columnFilters, pagination, globalFilter]);

  function onRemove(row: any) {
    setselectedrow(row._id || row.id);
    setopenmodal(true);
  }

  async function onConfirmRemove() {
    setIsSaving(true);
    if (!props.onRemove)
      try {
        await axiosInstance.delete(props.dataUrl + "/" + selectedrow);
      } catch (e) {}
    else await props.onRemove(selectedrow);
    setIsSaving(false);
    setdata(data?.filter((elt) => (elt._id || elt.id) != selectedrow));
    setopenmodal(false);
    setselectedrow("");
  }

  const table = useMaterialReactTable({
    columns: props.columns,
    data: data || [],
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    initialState: props.initialState,
    state: {
      isLoading: isLoading,
      isSaving: isSaving,
      columnFilters,
      globalFilter,
      pagination,
      sorting,
    },
    localization: MRT_Localization_FR,
    enableRowNumbers: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnFilters: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionActionsColumn: "first",
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableToolbarInternalActions: true,
    enableRowActions: true,
    //enableRowOrdering:true,
    // isMultiSortEvent: () => true,
    // enableMultiRowSelection: true,
    // enableColumnPinning: true,
    // enableColumnOrdering: true,
    // selectAllMode: "all",
    // enableRowSelection: true,
    // enableBatchRowSelection: true,
    renderRowActions: (p) => (
      <div style={{ display: "flex" }}>
        {!props.hideEdit && (
          <Link to={(p.row.original._id || p.row.original.id) + ""}>
            <span className="action">
              <img src={!disableEdit ? "/images/edit.svg" : "/images/eye.svg"} alt="modifier" />
            </span>
          </Link>
        )}
        {!props.hideDelete && (
          <span
            style={{ pointerEvents: disableDelete ? "none" : "auto", opacity: disableDelete ? 0.2 : 1 }}
            className="action"
            onClick={() => onRemove(p.row.original)}
          >
            <img style={{ marginLeft: 10 }} src="/images/remove.svg" alt="supprimer" />
          </span>
        )}
        {props.extracolumnActions && props.extracolumnActions(p)}
      </div>
    ),

    muiTableBodyRowProps: ({ staticRowIndex }) => ({
      className: staticRowIndex % 2 == 0 ? "rowpair" : "rowimpair",
    }),
    enableColumnActions: false,
  });
  const state = table.getState();
  return (
    <div className="apptable">
      <div className="row tabletop">
        <div className="col">
          <h4 style={{ fontWeight: 600 }} className="subtitle">
            {props.title}
            {data && <span className="indicator">{data.length}</span>}
          </h4>
          {state.globalFilter || state.columnFilters?.length ? (
            <span className="legend">
              {table.getFilteredRowModel().rows.length} {props.title?.toLowerCase()} trouvés
            </span>
          ) : null}
        </div>
        {props.showExport && (
          <div className="col-auto">
            {!props.onExport ? (
              <>
                <CsvDownloader csvRows={props.csvRows} table={table} columns={props.columns} />
              </>
            ) : (
              <Button onClick={props.onExport} className="btn-secondary addbtn" title="Télecharger la liste" />
            )}
          </div>
        )}
        {!props.hideAdd && (
          <div className="col-auto ">
            <Link style={{ pointerEvents: disableAdd ? "none" : "auto" }} to={disableAdd ? (undefined as any) : "add"}>
              <Button
                style={{ pointerEvents: disableAdd ? "none" : "auto", opacity: disableAdd ? 0.2 : 1 }}
                className="btn-primary addbtn"
                title="Ajouter"
              />
            </Link>
            <Link className="addsm" to="add">
              <img width={32} height={32} src="/images/plus.svg" alt="modifier" />
            </Link>
          </div>
        )}
      </div>
      <MaterialReactTable table={table} />
      <Modal
        show={openmodal}
        title={props.deleteTitle || "Confirmer la Suppression"}
        description={
          props.deleteDesc ||
          "Êtes-vous sûr de vouloir supprimer cet élément ? Cette action est irréversible et entraînera la perte définitive de toutes les données associées. "
        }
        onClose={() => {
          setopenmodal(false);
          setselectedrow("");
        }}
        onConfirm={onConfirmRemove}
      />
    </div>
  );
}
interface TabProps {
  title?: string;
  extracolumnActions?:any;
  columns?: any;
  data?: any[];
  onTabStateChange?: Function;
  isLoading?: boolean;
  isSaving?: boolean;
  onRemove?: Function;
  onExport?: Function;
  onEdit?: Function;
  onAdd?: Function;
  hideAdd?: boolean;
  hideDelete?: boolean;
  hideEdit?: boolean;
  showExport?: boolean;
  deleteTitle?: string;
  csvRows?: any[];
  deleteDesc?: string;
  permission?: any;
  dataUrl?: any;
  csvDataFn?: Function;
  mapData?: any;
}
interface TabContentProps extends TabProps {
  initialState: TabStateProps;
}
interface TabStateProps {
  columnFilters: MRT_ColumnFiltersState;
  globalFilter: string;
  sorting: MRT_SortingState;
  pagination: MRT_PaginationState;
}
