import moment from "moment";
import React from "react";
import Table from "../../../components/table/table";
import { Rating } from "react-simple-star-rating";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../../components/form/button/button";

export default function NotesJobbeurslistPage() {
  const [searchParams] = useSearchParams();
  const mission = searchParams.get("mission");
  const navigate = useNavigate();
  const columns = [
    {
      accessorKey: "idMission.idUser.NomEntreprise",
      header: "Nom de l'entreprise",
    },
    {
      accessorKey: "idMission.idJobbeur.nom",
      header: "Jobbeur",
    },
    {
      accessorKey: "moyen",
      header: "Note moyenne",
      size: 50,
      Cell: ({ cell }: any) => {
        const row = cell.row.original;
        const moy = ((+row.respect || 0) + (+row.qualite || 0) + (+row.comportement || 0) + (+row.conformite || 0)) / 4;
        return (
          <span>
            <Rating size={20} allowFraction initialValue={moy} />
          </span>
        );
      },
    },
    {
      accessorKey: "respect",
      header: "Ponctualité",
      size: 50,
      Cell: ({ cell }: any) => {
        return (
          <span>
            <Rating size={20} allowFraction initialValue={+cell.getValue() || 0} />
          </span>
        );
      },
    },
    {
      accessorKey: "qualite",
      header: "Qualité",
      size: 50,
      Cell: ({ cell }: any) => {
        return (
          <span>
            <Rating size={20} allowFraction initialValue={+cell.getValue() || 0} />
          </span>
        );
      },
    },
    {
      accessorKey: "comportement",
      header: "Comportement",
      size: 50,
      Cell: ({ cell }: any) => {
        return (
          <span>
            <Rating size={20} allowFraction initialValue={+cell.getValue() || 0} />
          </span>
        );
      },
    },
    {
      accessorKey: "conformite",
      size: 50,
      header: "Conformité",
      Cell: ({ cell }: any) => {
        return (
          <span>
            <Rating size={20} allowFraction initialValue={+cell.getValue() || 0} />
          </span>
        );
      },
    },
    {
      accessorKey: "commentaire",
      header: "Commentaire",
    },
    {
      accessorFn: (originalRow: any) => new Date(originalRow.created_at),
      header: "Date de création",
      filterVariant: "date-range",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => moment(+cell.getValue()).format("DD/MM/YYYY"),
      type: "date",
    },
    {
      accessorKey: "mission",
      header: "Mission",
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: any) => {
        const id = cell?.row?.original?.idMission?._id;
        return (
          <Link to={"/missions/" + id} className={`btn btn-secondary btnsmall`} title="Voir">
            <span>Voir</span>
          </Link>
        );
      },
    },
  ];

  return (
    <Table
      mapData={(elt: any) => {
        if (elt?.idMission?.idJobbeur) {
          const nom = elt?.idMission?.idJobbeur?.nom;
          const prenom = elt?.idMission?.idJobbeur?.prenom;
          elt.idMission.idJobbeur.nom = prenom + " " + nom;
        }
        return elt;
      }}
      hideAdd
      hideEdit
      dataUrl={"notes/jobbeur" + (mission ? "?mission=" + mission : "")}
      columns={columns}
      permission="notesjobbeur"
      title="Notes jobbeur"
    />
  );
}
