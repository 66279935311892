/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FormLayout from "../../components/formlayout/formlayout";
import axiosInstance from "../../config/axios";
import TextInput from "../../components/form/inputs/text-input/text-input";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../utils/utils";
import Label from "../../components/form/label/label";
import File from "../../components/file/file";

export default function EditAddSecteurPage() {
  const [loading, setIsLoading] = useState(false);
  const [notFound, setnotFound] = useState(false);
  const [photo, setphoto] = useState("");
  const { id } = useParams();
  const [file, setfile] = useState("");
  const location = useLocation();
  const isAdd = location.pathname?.endsWith("add") ? true : false;
  const [data, setdata]: any = useState({});
  const navigate = useNavigate();
  async function onSubmit(form: any) {
    if (!file && !data.video) return showToast("Vidéo de présentation est obligatoire");
    if (!photo && !data.photo) return showToast("Photo de secteur est obligatoire");
    const formData = new FormData();
    if (photo) {
      formData.append("photo", photo);
    }
    if (file) formData.append("video", file);
    formData.append("name", form.name);
    if (form.description != undefined) formData.append("description", form.description);
    try {
      if (isAdd) {
        await axiosInstance.post("secteurs", formData);
        showToast("Ajout avec succés", "success");
      } else {
        await axiosInstance.patch("secteurs/" + id, formData);
        showToast("Modification bien enregistré", "success");
      }
      navigate(-1);
    } catch (e: any) {
      if (e?.response?.status == 409) showToast("Nom prise par une autre zone");
    }
  }

  async function getData() {
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.get("secteurs" + "/" + id);
      setdata(data.data);
    } catch (e: any) {
      if (e.response?.status == 404) setnotFound(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (!isAdd) getData();
  }, [isAdd]);

  return (
    <FormLayout
      permission={"secteurs"}
      title="secteur"
      img=""
      onSubmit={onSubmit}
      loading={loading}
      notFound={notFound}
      isAdd={isAdd}
    >
      <div className="row" style={{ display: "flex", flexGrow: 1 }}>
        <div className="col-sm-12 col-md-12 ">
          <TextInput value={data.name} required="Nom invalide" name="name" label="Nom" />
        </div>
        <div className="col-sm-12 col-md-12 ">
          <TextInput value={data.description} name="description" label="Description" />
        </div>
        <div className="col-sm-12 col-md-12 mb-4 ">
          <File
            minHeight={180}
            maxWidth={180}
            onSelect={(val: any) => setphoto(val)}
            label="Image"
            filepath={data.photo ? data.photo : ""}
          />
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 row ">
            <Label label="Vidéo de présentation" />
            <div className="col-sm-12 col-md  ">
              <input
                onChange={(e: any) => setfile(e.target.files[0])}
                accept="video/mp4,video/x-m4v,video/*"
                className="form-control"
                type="file"
              />
            </div>
          </div>
        </div>
        {data.video && (
          <video
            style={{ marginTop: 20, backgroundColor: "black" }}
            title="Video"
            width="100%"
            controls
            height="315"
            src={process.env.REACT_APP_SERVER_URL + "/" + data.video}
          ></video>
        )}
      </div>
    </FormLayout>
  );
}
