/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import FormLayout from "../../components/formlayout/formlayout";
import axiosInstance from "../../config/axios";
import TextInput from "../../components/form/inputs/text-input/text-input";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../utils/utils";
import SelectInput from "../../components/form/inputs/select-input/select-input";
import DateTimeInput from "../../components/form/inputs/datetime-input/date-time-input";
import PlacesInput from "../../components/form/inputs/places-input/places-inputs";
import Label from "../../components/form/label/label";
import { getStatus, statusMission } from "./list.page";
import Switch from "@mui/material/Switch";
import moment from "moment";
import Modal from "../../components/modal/modal";

export default function EditAddMissionPage() {
  const [loading, setIsLoading] = useState(false);
  const [notFound, setnotFound] = useState(false);
  const { id } = useParams();
  const [langues, setlangues] = useState([]);
  const [competences, setcompetences] = useState([]);
  const [secteurs, setsecteurs] = useState([]);
  const [disablePaiement, setdisablePaiement] = useState(false);
  const [metiers, setmetier] = useState([]);
  const location = useLocation();
  const [annulation, setannulation]: any = useState({});
  const isAdd = location.pathname?.endsWith("add") ? true : false;
  const [data, setdata]: any = useState({});
  const [jobbeur, setjobbeur] = useState([]);
  const [secteur, setsecteur] = useState("");
  const [dateStart, setDateStart]: any = useState("");
  const [dateEnd, setDateEnd]: any = useState("");
  const [entreprises, setentreprises] = useState([]);
  const [payment, setpayment]: any = useState("");
  const [refund, setrefund]: any = useState("");
  const [transfert, settransfert]: any = useState("");
  const navigate = useNavigate();
  const [openmodal, setopenmodal] = useState(false);
  async function onSubmit(form: any) {
    try {
      form.metiers = [form.metiers];
      form = { ...form, requirePayment: !disablePaiement, ...form.adresse, ville: form.adresse.seuleVille };
      if (!form.idJobbeur) form.idJobbeur = undefined;
      if (isAdd) {
        await axiosInstance.post("missions", form);
        showToast("Ajout avec succés", "success");
      } else {
        await axiosInstance.patch("missions/" + id, form);
        showToast("Modification bien enregistré", "success");
      }
      navigate(-1);
    } catch (e: any) {
      if (e?.response?.status == 409) showToast("Nom prise par une autre zone");
    }
  }
  function statusPayment() {
    if (data.status == "annulee") {
      const remboursement =
        data.refundList?.length > 0
          ? data.refundList.reduce((sum: any, refund: any) => sum + refund.amount, 0)
          : refund?.amount
            ? refund?.amount / 100
            : "";
      if (refund?.status == "succeeded") return { title: remboursement + " € remboursé", class: "badge-blue" };
      if (refund?.status == "pending")
        return { title: remboursement + " € en cours de remboursement", class: "badge-warning" };
      else if (!payment && data.requirePayment) return { title: "Aucun paiement reçu", class: "badge-danger" };
      else return { title: "Echec", class: "badge-danger" };
    } else if (payment) {
      if (payment.status == "succeeded") return { title: "Payée", class: "badge-success" };
      if (payment.status == "canceled" || payment.status == "failed") return { title: "Échoué", class: "badge-danger" };
      return { title: "En attente", class: "badge-warning" };
    } else {
      if (data.paymentScheduleDate) {
        return { title: "Planifié le " + moment(data.paymentScheduleDate).format("DD/MM/YYYY"), class: "badge-violet" };
      }
    }
    return { title: "En attente", class: "badge-warning" };
  }
  function statusTransfert() {
    if (data.status == "annulee") {
      return { title: "Annulée", class: "badge-danger" };
    } else {
      if (transfert) {
        return { title: "Terminée", class: "badge-success" };
      } else return { title: "En attente", class: "badge-warning" };
    }
  }
  async function getData() {
    setIsLoading(true);
    try {
      const prms = [
        axiosInstance.get("/users/select/jobbeur"),
        axiosInstance.get("/users/select/entreprise"),
        axiosInstance.get("/competences"),
        axiosInstance.get("/secteurs"),
        axiosInstance.get("/metiers"),
        axiosInstance.get("/langues"),
      ];
      if (!isAdd) prms.push(axiosInstance.get("missions" + "/" + id));
      const [jobbeurData, entrepriseData, resCompet, resSecteur, resMetier, resLangue, missionData] =
        await Promise.all(prms);
      setjobbeur(jobbeurData.data.data);
      setentreprises(entrepriseData.data.data);
      setcompetences(resCompet?.data);
      setsecteurs(resSecteur?.data);
      setmetier(resMetier?.data);
      setlangues(resLangue?.data);
      if (missionData) {
        setsecteur(missionData?.data?.data?.secteur._id);
        missionData.data.data = { originstatus: missionData.data.data.status, ...getStatus(missionData.data.data) };
        setDateStart(missionData?.data?.data?.dateDebut);
        setDateEnd(missionData?.data?.data?.dateFin);
        setdata(missionData?.data?.data);
        setdisablePaiement(!missionData?.data?.data?.requirePayment);
        setpayment(missionData?.data?.payment);
        setrefund(missionData?.data?.refund);
        settransfert(missionData?.data?.transfert);
        if (missionData?.data?.annulation) setannulation(missionData?.data?.annulation);
      }
    } catch (e: any) {
      if (e.response?.status == 404) setnotFound(true);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    getData();
  }, [isAdd]);
  const startDate = useRef(new Date(new Date().getTime() + 15 * 60 * 1000));

  async function onConfirmPay() {
    try {
      await axiosInstance.patch("missions/jobbeur/pay/" + data._id);
      showToast("Votre transaction a été créée avec succès. ", "success");
      await getData();
    } catch (e: any) {
      showToast(e?.response?.data?.message);
    }
    setopenmodal(false);
  }
  function onPayJobbeur() {
    setopenmodal(true);
  }

  return (
    <FormLayout
      permission={"missions"}
      title="mission"
      img=""
      onSubmit={onSubmit}
      loading={loading}
      notFound={notFound}
      onSubmitSecond={
        statusMission(data.status).title == "Terminée" && statusTransfert().title != "Terminée"
          ? onPayJobbeur
          : undefined
      }
      secondtitle={"Payer le jobbeur"}
      isAdd={isAdd}
    >
      <Modal
        show={openmodal}
        title={"Confirmer le transfert"}
        description={"Êtes-vous sûr de vouloir payer ce jobbeur ? "}
        onClose={() => {
          setopenmodal(false);
        }}
        onConfirm={onConfirmPay}
      />
      <div
        className="row"
        style={{
          display: "flex",
          flexGrow: 1,
          pointerEvents: data.status && (data.status == "terminée" || data.status == "annulee") ? "none" : "auto",
        }}
      >
        {!isAdd && (
          <div className={"col-sm-12 col-md"}>
            <Label label="Statut" />
            <div className="form-control">
              <div className={statusMission(data.status).class}>{statusMission(data.status).title}</div>
            </div>
          </div>
        )}
        {!isAdd && data.status == "annulee" && (
          <div className="col-sm-12 col-md ">
            <Label label="Commentaire" />
            <div className="form-control">
              {annulation?.canceledBy == "Entreprise" && data.originstatus == "annulee"
                ? "Annulée par l'entreprise"
                : data.requirePayment && !payment
                  ? "Aucun paiement reçu"
                  : "Aucun Jobbeur trouvé"}
            </div>
          </div>
        )}
        {!isAdd && (
          <div className="col-sm-12 col-md ">
            <Label label="Désactiver le paiement" />
            <div>
              <Switch
                disabled={!isAdd}
                onChange={() =>
                  setdisablePaiement((old: any) => {
                    return !old;
                  })
                }
                checked={disablePaiement}
              />
            </div>
          </div>
        )}

        {!isAdd && !disablePaiement && (
          <div className="col-sm-12 col-md ">
            <Label label="Statut de paiement" />
            <div className="form-control">
              <div className={statusPayment().class}>{statusPayment().title}</div>
            </div>
          </div>
        )}
        {!isAdd && (
          <div className="col-sm-12 col-md ">
            <Label label="Statut de transfert" />
            <div className="form-control">
              <div className={statusTransfert().class}>{statusTransfert().title}</div>
            </div>
          </div>
        )}
        <div className="row" style={{ padding: 0, margin: 0 }}>
          <div className="row" style={{ padding: 0, margin: 0, marginTop: 12 }}>
            <div className="col-sm-12 col-md-4 ">
              <SelectInput
                disabled={!isAdd && !data.manually}
                required={"Sélectionner une entreprise"}
                options={entreprises?.map((elt: any) => ({ label: elt.NomEntreprise, value: elt._id }))}
                value={data.idUser?._id}
                name="idUser"
                label="Entreprise"
              />
            </div>
            <div className={"col-sm-12 col-md"}>
              <TextInput
                disabled={data.status == "terminée" || data.status == "annulee"}
                label="Etablissement"
                value={data.titre}
                required="Etablissement invalide"
                name="titre"
              />
            </div>
            <div className="col-sm-12 col-md">
              <PlacesInput
                value={{
                  adresse: data.location?.coordinates[0]
                    ? data.adresse
                    : !isAdd
                      ? data.localisation + " " + data.codePostal + " " + data.ville
                      : "",
                  location: data.location,
                  lng: data.location?.coordinates[0],
                  lat: data.location?.coordinates[1],
                }}
                disabled={data.status == "terminée" || data.status == "annulee"}
                required="Adresse invalide"
                name="adresse"
                label="Adresse"
              />
            </div>
            {isAdd && (
              <div className="col-sm-12 col-md-2 ">
                <Label label="Désactiver le paiement" />
                <div>
                  <Switch
                    disabled={!isAdd}
                    onChange={() =>
                      setdisablePaiement((old: any) => {
                        return !old;
                      })
                    }
                    checked={disablePaiement}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="col-sm-12 col-md-4 ">
            <SelectInput
              options={jobbeur?.map((elt: any) => ({ label: elt.prenom + " " + elt.nom, value: elt._id }))}
              value={data.idJobbeur?._id}
              name="idJobbeur"
              disabled={data.status == "terminée" || data.status == "annulee"}
              label="Jobbeur"
              isClearable={true}
            />
          </div>
          <div className="col-sm-12 col-md-4 ">
            <TextInput
              label="Rémunération"
              type="number"
              required="Rénumeration invalide"
              name="remuneration"
              value={data.remuneration}
              disabled={data.status == "terminée" || data.status == "annulee" || payment?.status}
            />
          </div>
          <div className="col-sm-12 col-md-4 ">
            <TextInput
              label="Commission"
              disabled={data.status == "terminée" || data.status == "annulee" || payment?.status}
              value={isAdd ? undefined : data.commission || (+data.remuneration * (+data.zone?.commission || 25)) / 100}
              validate={(val: any, form: any) => {
                if (+form.remuneration && +val > +form.remuneration) return "Commission invalide";
                return true;
              }}
              type="number"
              required="Commission invalide"
              name="commission"
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 ">
          <DateTimeInput
            required="Date début invalide"
            type="datetime"
            disabled={data.status == "terminée" || data.status == "annulee"}
            onChange={(val: any) => {
              setDateStart(val);
              setDateEnd(undefined);
            }}
            value={data.dateDebut}
            minDateTime={isAdd ? startDate.current : undefined}
            //   maxDateTime={dateEnd}
            name="dateDebut"
            label="Date début"
          />
        </div>
        <div className="col-sm-12 col-md-4 ">
          <DateTimeInput
            required="Date fin invalide"
            type="datetime"
            value={dateEnd}
            disabled={!dateStart || data.status == "terminée" || data.status == "annulee"}
            minDateTime={isAdd && dateStart ? new Date(new Date(dateStart)?.getTime() + 60 * 60 * 1000) : undefined}
            onChange={setDateEnd}
            name="dateFin"
            label="Date Fin"
          />
        </div>
        <div className="col-sm-12 col-md-4 ">
          <TextInput
            validate={(val: any) => {
              //if (+val <= 0 || +val > 24) return "Durée de mission invalide";
              return true;
            }}
            required="Durée de mission invalide"
            type="number"
            disabled={data.status == "terminée" || data.status == "annulee" || payment?.status}
            value={data.duree}
            name="duree"
            label="Durée"
          />
        </div>
        <div className={"col-sm-12 col-md"}>
          <SelectInput
            options={secteurs?.map((elt: any) => ({ value: elt._id, label: elt.name }))}
            required="Secteurs invalide "
            value={secteur || data?.secteur?._id}
            name="secteur"
            disabled={data.status == "terminée" || data.status == "annulee"}
            label="Secteur"
            onChange={(value: any) => {
              setsecteur(value.value);
            }}
          />
        </div>
        <div className="col-sm-12 col-md ">
          <SelectInput
            options={metiers
              ?.filter((elt: any) => elt.secteur._id == secteur)
              .map((elt: any) => ({ value: elt._id, label: elt.name }))}
            required="Métiers invalide"
            value={data?.metiers?.length ? data?.metiers[0]?._id : undefined}
            disabled={data.status == "terminée" || data.status == "annulee"}
            name="metiers"
            label="Métier"
          />
        </div>
        <div className="col-sm-12 col-md ">
          <SelectInput
            options={langues?.map((elt: any) => ({ value: elt._id, label: elt.name }))}
            name="langues"
            value={data?.langues?.map((elt: any) => elt?._id)}
            multiple
            disabled={data.status == "terminée" || data.status == "annulee"}
            label="Langues"
          />
        </div>
        <div className="col-sm-12 col-md-12 ">
          <SelectInput
            options={competences
              ?.filter((elt: any) => elt.secteur._id == secteur)
              .map((elt: any) => ({ value: elt._id, label: elt.name }))}
            required="Compétences invalide"
            value={data?.competences?.filter((elt: any) => elt.secteur == secteur)?.map((elt: any) => elt?._id)}
            multiple
            name="competences"
            disabled={data.status == "terminée" || data.status == "annulee"}
            label="Compétences"
          />
        </div>
        <div className="col-sm-12 col-md-12 ">
          <TextInput
            disabled={data.status == "terminée" || data.status == "annulee"}
            type="textarea"
            label="Description"
            name="description"
            value={data.description}
          />
        </div>
      </div>
    </FormLayout>
  );
}
